<template>
  <div class="repository">
    <Head :headdata="headdata"></Head>
    <div class="repositoryMain common-main">
      <h2 class="title_zh">
        知识库
        <div class="fenxBox">
          <img class="fenx" src="../assets/images/icon_fenx.png" alt />
          <p>分享</p>
          <share/>
        </div>
      </h2>
      <h2 class="title_en">Repository</h2>
      <!-- <p class="title_text">bicc以不断提升前瞻性研究经验和决策落地能力为目标， 积累大量专题研究成果，<br>致力于打造旅游综合开发运营知识库， 始终追求立足行业前端。</p> -->
      <!-- <div class="search">
        <input type="text" placeholder="按分类检索" />
        <img class="searchImg" src="../assets/images/icon_search.png" alt />
      </div>-->
      <div class="repositoryLists">
        <ul>
          <li>
            <h1>
              案例库
              <span class="blue_text">2000</span>
              <img src="../assets/images/icon_jia.png" alt />
            </h1>
            <p class="list_title_zh">bicc湾创深度调研与评析的国内外经典热点案例库</p>
            <p
              class="list_title_en"
            >bicc investigates, evaluates and analyzes<br>the classic important case libraries both in home and abroad</p>
          </li>
          <li>
            <h1>
              供应商库
              <span class="blue_text">100</span>
              <img src="../assets/images/icon_jia.png" alt />
            </h1>
            <p class="list_title_zh">bicc湾创长期合作的为行业提供领先创意服务的供应商库</p>
            <p
              class="list_title_en"
            >Bicc provides the industry with a supplier<br>database of long-term corporation and leading and creative service</p>
          </li>
          <!-- <li>
            <h1>
              设备库
              <span class="blue_text">50</span>
              <img src="../assets/images/icon_jia.png" alt />
            </h1>
            <p class="list_title_zh">整理行业主要供应商提供的文化旅游项目的设备库</p>
            <p
              class="list_title_en"
            >Powered and unpowered equipment libraries provided by major industry suppliers</p>
          </li> -->
          <li>
            <h1>
              工具库
              <span class="blue_text">20</span>
              <img src="../assets/images/icon_jia.png" alt />
            </h1>
            <p class="list_title_zh">bicc湾创实践专业化、体系化、标准化策划工作的方法库</p>
            <p
              class="list_title_en"
            >Bicc creates a professional, systematic and<br>standardized planning database system</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/header.vue";
import share from "@/components/share.vue";
export default {
  data() {
    return {
      headdata: this.$t("m.projectHeaddatc"),
    };
  },
  components: {
    Head,
    share
  },
  updated() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  methods: {
    getdata() {},
  },
};
</script>

<style lang="scss" scoped>
.repository {
  height: 100%;
  width: 100%;
  .repositoryMain {
    width: 100%;
    padding: 78px 200px 0 200px;

    .title_zh {
      color: #000;
      font-size: 34px;
      cursor: pointer;
      .fenxBox {
        float: right;
        cursor: pointer;
        .fenx {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 12px;
          color: #808080;
        }
      }
    }
    .title_en {
      cursor: pointer;
      // margin-bottom: 60px;
      font-size: 24px;
    }
    .title_text {
      font-size: 18px;
      // margin-bottom: 60px;
      color: #000;
    }
    .repositoryLists {
      ul {
        li {
          height: 290px;
          padding-top: 80px;
          border-bottom: 1px solid #ccc;
          h1 {
            font-size: 40px;
            color: #000;
            margin-bottom: 20px;
            font-weight: bold;
            .blue_text {
              color: #0066b2;
              margin-left: 25px;
            }
            img {
              height: 25px;
              width: 25px;
              vertical-align: text-top;
              margin-left: 5px;
            }
          }
          .list_title_zh {
            color: #000;
            font-size: 24px;
            font-weight: 500;
          }
          .list_title_en {
            color: #0066b2;
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .repositoryLists {
     ul li h1 {
       font-size: 26px !important;
       img {
         width:18px !important;height:18px !important;
       }
    }
    .list_title_zh {font-size: 24px !important;}
  }
}
</style>